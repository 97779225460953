<script lang="ts">
	import ctaBanner from '$assets/cta-banner.webp';
	import ctaBannerSm from '$assets/cta-banner-sm.webp';
	import ctaBg from '$assets/cta-bg.jpg';
	import ctaBgSm from '$assets/cta-bg-sm.jpg';
	import { goto } from '$app/navigation';
	import Worm from '$lib/icons/worm.svelte';
	import { getContext } from 'svelte';
	import { LINK_BOOKING, LOCALIZELINK } from '$utils/link';
	import type { LANGUAGE } from '$lib/constants/locale';
	import GlowingButton from '$components/atoms/GlowingButton.svelte';
	let t = getContext('page-contents-landing') as Record<string, string>;
	let lang = getContext('lang') as LANGUAGE;
	export let variant: 'sm' | 'lg' | 'lg2' = 'lg';
	const handleBooking = () => {
		goto(LOCALIZELINK(lang, LINK_BOOKING));
	};
</script>

{#if variant === 'lg'}
	<div
		class="md:max-w-1120px max-w-545px mx-a min-h-420px relative w-full flex-shrink-0 bg-[#F2F3FE] px-2.5"
	>
		<div class="absolute bottom-0 left-0 w-full">
			<img src={ctaBanner} alt="cta banner" class="hidden md:block" loading="lazy" />
			<img src={ctaBannerSm} alt="cta banner" class="block w-full md:hidden" loading="lazy" />
		</div>
		<div
			class="top-15 absolute flex transform flex-col items-center justify-center md:right-10 md:top-1/2 md:-translate-y-1/2 md:items-end md:justify-end"
		>
			<div class="sub-heading text-center md:text-right">{t.banner_the_best_transfer_mallorca}</div>
			<GlowingButton
				class="min-w-189px max-w-281px h-53px top-.8rem relative"
				on:click={handleBooking}
			>
				{t.book_transfer_now}
			</GlowingButton>
		</div>
	</div>
{:else if variant === 'lg2'}
	<div class="mx-a min-h-383px relative w-full flex-shrink-0 bg-[#F2F3FE] px-2.5">
		<div class="max-h-383px absolute bottom-0 left-0 right-0 top-0 w-full">
			<img
				src={ctaBg}
				alt="cta banner"
				style="object-fit: cover; object-position: right;"
				class="hidden h-full w-full md:block"
				loading="lazy"
			/>
			<img
				src={ctaBgSm}
				alt="cta banner"
				style="object-fit: cover; object-position: bottom;"
				class="block h-full w-full md:hidden"
				loading="lazy"
			/>
		</div>
		<div
			class="md:max-w-1120px absolute left-1/2 top-1/2 mx-auto flex h-full w-full -translate-x-1/2 -translate-y-1/2 items-center"
		>
			<div class="flex-center mx-5 flex flex-col md:items-start">
				<h3 class="sub-heading text-center md:text-left">
					{t.banner_2_planning_a_trip}
				</h3>
				<GlowingButton
					class="min-w-189px max-w-281px h-53px top-.8rem relative"
					on:click={handleBooking}
				>
					{t.book_transfer_now}
				</GlowingButton>
			</div>
		</div>
	</div>
{:else}
	<div class="w-360px h-302px card relative bg-[#D7DBFF]">
		<div class="blur-10px absolute left-0 top-0 h-full w-full" style="mix-blend-mode: hard-light;">
			<div class="img-container w-360px h-224px overflow-clip transition-all duration-500">
				<Worm />
			</div>
			<div class="absolute bottom-0 left-0 scale-y-[-1] transform">
				<Worm />
			</div>
		</div>
		<div
			class="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform flex-col items-center gap-8"
		>
			<div class="karla-medium text-26px font-600 relative z-10 text-center">
				{t.banner_3_travel_with_us} <br />
				{t.banner_3_best_transfer_company}
			</div>
			<GlowingButton
				class="h-70px w-281px rubik-regular text-6 relative z-10 mx-5 flex-shrink-0 cursor-pointer"
				on:click={handleBooking}
			>
				{t.book_transfer_now}
			</GlowingButton>
		</div>
	</div>
{/if}

<style type="postcss">
	.sub-heading {
		@apply karla-bold z-1 max-w-550px text-grey-800 relative w-full;
		font-size: clamp(34px, 3vw + 1rem, 48px);
	}
</style>
