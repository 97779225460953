<script lang="ts">
	import autoAnimate from '@formkit/auto-animate';
	import ArticleCard from '$components/landing/articles/ArticleCard.svelte';
	import type { Article } from '$types/articles.types';
	import isDesktop from '$store/isDesktop';
	import CtaBanner from './CtaBanner.svelte';
	import { getContext } from 'svelte';
	import { LOCALIZELINK } from '$utils/link';
	import type { LANGUAGE } from '$lib/constants/locale';
	export let blogsInfo: Article[];

	export let maxItemsToShow = 8;
	export let showCtaBanner = false;
	let t = getContext('page-contents-landing') as Record<string, string>;

	let categories: Article['category'][] = [
		{
			label: t.articles_destinations as Article['category']['label'],
			value: 'destinations'
		},
		{
			label: t.articles_services as Article['category']['label'],
			value: 'services'
		},
		{
			label: t.articles_blog as Article['category']['label'],
			value: 'blog'
		}
	];
	let active: Article['category'] = {
		label: t.articles_destinations as Article['category']['label'],
		value: 'destinations'
	};

	const handleCategoryClick = (category: Article['category']) => {
		active = category;
	};
	$: itemsToShow = $isDesktop ? maxItemsToShow : 4;

	$: filteredArticles = blogsInfo.filter((article) => article.category.value === active.value);

	$: articlesToShow = filteredArticles.slice(0, itemsToShow);
	const handleShowMore = () => {
		if (itemsToShow < filteredArticles.length) {
			itemsToShow += $isDesktop
				? Math.min(3, filteredArticles.length - itemsToShow)
				: Math.min(2, filteredArticles.length - itemsToShow);
		}
	};
	let lang = getContext('lang') as LANGUAGE;
</script>

<div class="">
	<div class="hide-scrollbar mb-10 flex flex-row items-center justify-start gap-3 overflow-x-auto">
		{#each categories as category}
			<button
				class="rounded-15px karla-medium-base flex-center gap-2.5 bg-white px-5 py-2.5 capitalize"
				class:border-grey-800={active.value === category.value}
				class:border-2={active.value === category.value}
				on:click={() => handleCategoryClick({ ...category })}
			>
				{category.label}
			</button>
		{/each}
	</div>
	<!-- this contains hidden links for SEO -->
	<div class="contents hidden h-0 w-0">
		{#each categories as category}
			<a
				href={LOCALIZELINK(lang, category.value)}
				aria-label={category.label}
				class="hidden h-0 w-0"
			/>
		{/each}
	</div>
	{#if articlesToShow.length === 0}
		<div class="text-grey-800 text-center">
			{t.no_articles_found}
		</div>
	{/if}
	<div
		class="grid place-content-center place-items-center !items-start gap-5 md:place-items-center"
		style="grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));"
		use:autoAnimate={{ duration: 300 }}
	>
		{#each articlesToShow as article, index}
			{#if showCtaBanner}
				{@const articleLength = articlesToShow.length}
				{#if articleLength >= 2 && articleLength < 5 && index === 1}
					<CtaBanner variant="sm" />
				{:else if articleLength > 4 && index === 4}
					<CtaBanner variant="sm" />
				{/if}
			{/if}
			<ArticleCard {article} />
		{/each}
	</div>
	<div class="flex-center">
		{#if itemsToShow < filteredArticles.length}
			<button
				class="cta min-w-189px max-w-281px h-53px top-.8rem relative"
				on:click={handleShowMore}
			>
				{t.show_more}
			</button>
		{/if}
	</div>
</div>
